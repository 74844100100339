.second-page-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 800px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 1000px) {
  .second-page-container {
    flex-direction: column;
  }
  .second-page-container .billboard-container {
    width: 100%;
  }
  .second-page-container .vacancy-container {
    width: 100%;
  }
}
