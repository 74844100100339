.tagmark-container {
  width: 50%;
  min-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.tagmark-container .background {
  position: relative;
  width: 45%;
  height: 45%;
  left: 15%;
}

.tagmark-container .girl {
  position: absolute;
  width: 20%;
  height: 45%;
  left: 61%;
}

.tagmark-container .right-leaf {
  position: absolute;
  width: 6%;
  height: 6%;
  left: 55%;
  top: 66%;
  z-index: 10;
}

.tagmark-container .left-leaf {
  position: absolute;
  width: 6%;
  height: 6%;
  left: 24%;
  top: 66%;
  z-index: 10;
}

.tagmark-container .mark {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 28%;
  left: 12%;
}

/*網美*/
.tagmark-container .tag-1 {
  position: absolute;
  font-size: 1.1em;
  left: 25%;
  top: 25%;
  color: #828282;
}

/*大自然*/
.tagmark-container .tag-2 {
  position: absolute;
  font-size: 1.1em;
  left: 0%;
  top: 35%;
  color: #828282;
}

/*桌子很大*/
.tagmark-container .tag-3 {
  position: absolute;
  font-size: 1.1em;
  left: 60%;
  top: 78%;
  color: #828282;
}

/*適合讀書*/
.tagmark-container .tag-4 {
  position: absolute;
  font-size: 1.1em;
  left: 30%;
  top: 75%;
  color: #828282;
}

/*貓貓蟲咖波*/
.tagmark-container .tag-5 {
  position: absolute;
  font-size: 1.1em;
  left: 40%;
  top: 27%;
  color: #828282;
}

/*外語交流*/
.tagmark-container .tag-6 {
  position: absolute;
  font-size: 1.1em;
  left: 80%;
  top: 50%;
  color: #828282;
}

/*流行音樂*/
.tagmark-container .tag-7 {
  position: absolute;
  font-size: 1.1em;
  left: 0%;
  top: 70%;
  color: #828282;
}

@media all and (max-width: 1000px) {
  .tagmark-container .tag-1 {
    font-size: 0.8em;
  }
  .tagmark-container .tag-2 {
    font-size: 0.8em;
  }
  .tagmark-container .tag-3 {
    font-size: 0.8em;
  }
  .tagmark-container .tag-4 {
    font-size: 0.8em;
  }
  .tagmark-container .tag-5 {
    font-size: 0.8em;
  }
  .tagmark-container .tag-6 {
    font-size: 0.8em;
  }
  .tagmark-container .tag-7 {
    font-size: 0.8em;
  }
}
