.cafe-container1 {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 18%;
  border-radius: 25px;
  box-shadow: 4px 4px 14px 4px rgba(0, 0, 0, 0.2);
  left: 0%;
  top: 20%;
  background-color: white;
}

.cafe-container1 .name {
  position: relative;
  font-size: 1em;
  height: 20%;
  width: 100%;
  border-radius: 25px;
  padding-left: 30px;
  padding-top: 10px;
  font-weight: 800;
}

.cafe-container1 .image-gallery {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  height: 45%;
  width: 100%;
  padding-top: 5px;
  align-items: center;
  justify-content: center;
}

.cafe-container1 .tag {
  position: relative;
  height: 20%;
  font-size: 0.9em;
  font-weight: 700;
  color: #90796e;
  padding-left: 20px;
}

.cafe-container1 .empty {
  position: relative;
  text-align: right;
  height: 15%;
  width: 100%;
  background-image: linear-gradient(90deg, #90796e 0%, #3a1e11 98.98%);
  border-radius: 0px 0px 0px 25px;
  font-weight: 700;
  padding-top: 0.2em;
  font-size: 0.7em;
  color: #fff;
  padding-right: 5px;
}

.cafe-container1 .image-gallery img {
  position: relative;
  width: 25%;
  border-radius: 10px;
  padding: 2px;
}

@media all and (max-width: 1000px) {
  .cafe-container1 {
    width: 38%;
    height: 22%;
    border-radius: 15px;
  }
  .cafe-container1 .name {
    font-size: 0.7em;
    padding-left: 0.8em;
  }
  .cafe-container1 .tag {
    font-size: 0.05em;
    padding-left: 1.5em;
  }
  .cafe-container1 .empty {
    font-size: 0.1em;
    border-radius: 0px 0px 0px 15px;
  }
}
