.title-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

.title-container .title-outer {
  display: flex;
  flex-direction: column;
}

.title-container .title-outer .hidden {
  position: relative;
  overflow: hidden;
}

.title-container .title-outer .hidden .top-title {
  font-size: 40px;
  font-weight: 600;
  transition: all 250ms ease-in-out;
  overflow: hidden;
}

.title-container .title-outer .hidden .block {
  background: #6d3318;
  height: 60px;
  font-weight: 600;
  position: absolute;
}

.title-container .title-outer .hidden .middle-title {
  font-size: 40px;
  transition: all 250ms ease-in-out;
  position: relative;
  font-weight: 600;
  color: #a35531;
}

.title-container .title-outer .hidden .buttom-title {
  font-size: 40px;
  font-weight: 600;
  transition: all 250ms ease-in-out;
}

@media all and (max-width: 1000px) {
  .title-container {
    width: 100%;
    height: 40%;
  }
  .title-container .title-outer .hidden .top-title {
    font-size: 2em;
  }
  .title-container .title-outer .hidden .middle-title {
    font-size: 2em;
  }
  .title-container .title-outer .hidden .buttom-title {
    font-size: 2em;
  }
}
