.sixth-page-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #141414;
}

.sixth-page-container .title {
  position: relative;
  font-weight: 700;
  font-size: 3em;
  color: #b19384;
  width: 100%;
  height: 10%;
  text-align: center;
  margin-top: 5%;
}

.sixth-page-container .content {
  position: relative;
  font-weight: 700;
  font-size: 2.5em;
  color: #ffffff;
  width: 100%;
  height: 8%;
  text-align: center;
}

.sixth-page-container .download {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 35%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.sixth-page-container .download .buttom {
  position: relative;
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sixth-page-container .footer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  height: 5%;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
}

.sixth-page-container .footer .information-container {
  position: relative;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sixth-page-container .footer .information-container .up {
  position: relative;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sixth-page-container .footer .information-container .up .terms {
  position: relative;
  text-align: center;
  color: #727272;
  font-size: 1em;
  border-color: #727272;
  border-right-style: solid;
  border-width: 0.1em;
  padding-right: 1em;
}

.sixth-page-container .footer .information-container .up .privacy {
  position: relative;
  text-align: center;
  color: #727272;
  font-size: 1em;
  border-color: #727272;
  border-right-style: solid;
  border-width: 0.1em;
  padding-right: 1em;
  padding-left: 1em;
}

.sixth-page-container .footer .information-container .up .phone {
  position: relative;
  text-align: center;
  color: #727272;
  font-size: 1em;
  padding-left: 1em;
}

.sixth-page-container .footer .information-container .down {
  position: relative;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1em;
  color: #727272;
}

.sixth-page-container .footer .blank {
  position: relative;
  width: 40%;
  height: 100%;
}

.sixth-page-container .footer .media {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 20%;
  height: 100%;
}

.sixth-page-container .footer .media .img-container {
  position: relative;
  width: 15%;
  height: 100%;
  padding-right: 2em;
}

@media all and (max-width: 1000px) {
  .sixth-page-container .download {
    flex-direction: column;
  }
  .sixth-page-container .download .buttom {
    height: 50%;
  }
  .sixth-page-container .footer {
    flex-direction: column;
    height: 20%;
  }
  .sixth-page-container .footer .information-container {
    width: 80%;
  }
  .sixth-page-container .footer .information-container .down {
    padding-top: 1em;
    text-align: center;
  }
  .sixth-page-container .footer .media {
    width: 80%;
  }
  .sixth-page-container .footer .media .img-container {
    padding-left: 1em;
    padding-right: 1em;
    width: 15%;
  }
  .sixth-page-container .title {
    font-size: 2.5em;
  }
  .sixth-page-container .content {
    font-size: 2em;
  }
}
