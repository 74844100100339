.first-page-container {
  width: 100vw;
  height: 100vh;
  min-height: 800px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 1000px) {
  .first-page-container {
    flex-direction: column-reverse;
  }
  .first-page-container .title-container {
    width: 100%;
  }
  .first-page-container .map-container {
    width: 100%;
  }
}
