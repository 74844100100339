.third-page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

@media all and (max-width: 1000px) {
  .third-page-container {
    flex-direction: column-reverse;
  }
  .third-page-container .freetag-container {
    width: 100%;
  }
  .third-page-container .tagmark-container {
    width: 100%;
  }
}
