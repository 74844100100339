.freetag-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

.freetag-container .freetag-outer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.freetag-container .freetag-outer .hidden {
  position: relative;
  overflow: hidden;
  width: 70%;
}

.freetag-container .freetag-outer .hidden .block {
  width: 0%;
  height: 100%;
  background-color: #5c2912;
  position: absolute;
}

.freetag-container .freetag-outer .hidden .title {
  position: relative;
  font-size: 2em;
  font-weight: 600;
  color: #90796e;
}

.freetag-container .freetag-outer .hidden-2 {
  position: relative;
  overflow: hidden;
}

.freetag-container .freetag-outer .hidden-2 .sub-title {
  position: relative;
  font-size: 1.5em;
  margin-top: 0.5em;
  font-weight: 600;
  color: #4f4f4f;
}

.freetag-container .freetag-outer .content-1 {
  position: relative;
  font-size: 1em;
  margin-top: 2.5em;
  color: #828282;
}

.freetag-container .freetag-outer .content-2 {
  position: relative;
  font-size: 1em;
  margin-top: 0.5em;
  color: #828282;
}

.freetag-container .freetag-outer .content-3 {
  position: relative;
  font-size: 1em;
  margin-top: 2.5em;
  color: #828282;
}

.freetag-container .freetag-outer .content-4 {
  position: relative;
  font-size: 1em;
  margin-top: 0.5em;
  color: #828282;
}

@media all and (max-width: 1000px) {
  .freetag-container .freetag-outer {
    width: 90%;
  }
  .freetag-container .freetag-outer .hidden .title {
    font-size: 1.7em;
  }
}
