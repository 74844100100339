.map-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media all and (max-width: 1000px) {
  .map-container {
    width: 100%;
    height: 50%;
  }
}
