.pin-container {
  position: absolute;
  z-index: 50;
  display: flex;
  flex-direction: column;
  left: 40%;
}

.pin-container .pin .pin-svg {
  width: 70%;
  height: 70%;
}

@media all and (max-width: 1000px) {
  .pin-container .pin .pin-svg {
    width: 30%;
    height: 30%;
  }
}
