.billboard-container {
  width: 50%;
  min-width: 300px;
  height: 100%;
  position: relative;
}

.billboard-container .board-back {
  position: relative;
  width: 90%;
  height: 70%;
  top: 15%;
  left: 15%;
}

.billboard-container .leaf {
  position: absolute;
  width: 9%;
  height: 9%;
  top: 69%;
  left: 15%;
}

.billboard-container .person {
  position: absolute;
  width: 30%;
  height: 45%;
  top: 33%;
  left: 80%;
}

.billboard-container .board {
  position: absolute;
  width: 45%;
  height: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 40%;
  top: 43%;
}

.billboard-container .board .row {
  position: relative;
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 0.7em;
}

.billboard-container .board .row .left {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
}

.billboard-container .board .row .left .top-title {
  position: relative;
  height: 30%;
  width: 100%;
  color: #fff;
  font-size: 0.7em;
}

.billboard-container .board .row .left .empty {
  background-color: #fff;
  position: absolute;
  width: 80%;
  height: 20%;
  top: 50%;
  z-index: 0;
  border-radius: 35px;
}

.billboard-container .board .row .left .taken-1 {
  background-color: #90796e;
  position: absolute;
  width: 70%;
  height: 20%;
  top: 50%;
  z-index: 50;
  border-radius: 25px;
}

.billboard-container .board .row .left .taken-2 {
  background-color: #90796e;
  position: absolute;
  width: 40%;
  height: 20%;
  top: 50%;
  z-index: 50;
  border-radius: 25px;
}

.billboard-container .board .row .left .taken-3 {
  background-color: #90796e;
  position: absolute;
  width: 30%;
  height: 20%;
  top: 50%;
  z-index: 50;
  border-radius: 25px;
}

.billboard-container .board .row .left .taken-4 {
  background-color: #90796e;
  position: absolute;
  width: 50%;
  height: 20%;
  top: 50%;
  z-index: 50;
  border-radius: 25px;
}

.billboard-container .board .row .left .taken-5 {
  background-color: #90796e;
  position: absolute;
  width: 10%;
  height: 20%;
  top: 50%;
  z-index: 50;
  border-radius: 25px;
}

.billboard-container .board .row .right {
  width: 40%;
  font-size: 0.7em;
  color: #fff;
}

@media all and (max-width: 1000px) {
  .billboard-container .board-back {
    width: 100%;
    left: 5%;
  }
  .billboard-container .board {
    width: 45%;
    left: 32%;
  }
  .billboard-container .board .row {
    padding: 0px;
  }
  .billboard-container .board .row .left .top-title {
    font-size: 0.05em;
  }
  .billboard-container .board .row .right {
    font-size: 0.1em;
  }
  .billboard-container .leaf {
    top: 75%;
    left: 5%;
  }
  .billboard-container .person {
    top: 38%;
    left: 72%;
  }
}
